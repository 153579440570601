<template>
    <div v-if="!item.hide">
        <div v-if="showOnPageIndicator" class="d-block snap-element instruction-box mb-0" :style="[styling, paddingX]">
            <OnPageIndicator />
        </div>
        <div
            v-else
            class="d-block snap-element instruction-box mb-0"
            :style="[styling, paddingX]"
            v-html="item.data.instruction"
        ></div>
    </div>
</template>

<script lang="ts">
import { CSSProperties, defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import OnPageIndicator from '../OnPageIndicator.vue'

export default defineComponent({
    name: 'InstructionPreview',
    components: {
        OnPageIndicator,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            style: 'document/documentStyle',
        }),
        sidePadding(): number {
            return 8 + (this.style.font_size * (this.style.font_space - 1)) / 2 + this.style.font_size * 0.1
        },
        bottomPadding(): number {
            return 8 + this.style.font_size * 0.1
        },
        styling(): CSSProperties {
            return {
                borderWidth: `${this.item.style.border_width}px`,
                borderStyle: this.item.style.border_style,
                borderColor: this.item.style.border_color,
                minHeight: `${this.style.font_space}em`, //lineheight
                borderRadius: '3px',
                boxSizing: 'content-box',
                paddingTop: '8px',
                paddingBottom: `${this.bottomPadding}px`,
            }
        },
        paddingX(): CSSProperties {
            if (this.item.style.border_width <= 0) return {}
            return {
                paddingLeft: `${this.sidePadding}px`,
                paddingRight: `${this.sidePadding}px`,
            }
        },
        showOnPageIndicator(): boolean {
            return !Boolean(this.item.data.instruction)
        },
    },
})
</script>
