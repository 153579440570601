<template>
    <div v-if="!isPublished" class="on-page-indicator">Your text will go here</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'OnPageIndicator',
    computed: {
        ...mapGetters({
            isPublished: 'document/isPublished',
        }),
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';

.on-page-indicator {
    color: $blank-space-border-color;
    font-style: italic;
    font-weight: bold;
}
</style>
