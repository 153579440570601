<template>
    <div class="card-body py-1">
        <div @drop="(e) => e.preventDefault()">
            <b-card class="mb-2 position-relative" no-body>
                <b-card-header
                    class="input-header py-0 px-1 d-flex flex-row-reverse justify-content-between align-items-center position-relative"
                >
                    <!-- Answer Key -->
                    <div class="d-flex flex-row align-items-center">
                        <label class="font-weight-bold f-11 text-muted mb-0">Answer Key</label>
                        <b-form-checkbox
                            :id="`answer-checkbox-${index}`"
                            v-model="itemData.has_answer"
                            name="options"
                            class="f-14 text-muted ml-2"
                            tabindex="-1"
                            switch
                            @change="updateData"
                        ></b-form-checkbox>
                    </div>
                </b-card-header>
                <b-card-body class="p-0">
                    <OpenResponseItemForm :index="index" :data="itemData" @input="handleInput" />
                </b-card-body>
            </b-card>
        </div>

        <fieldset class="fieldset my-2">
            <legend>FORMAT</legend>
        </fieldset>

        <!-- Answer Type -->
        <div class="w-75">
            <div class="answer-type d-flex flex-column mb-3">
                <Multiselect
                    ref="select"
                    v-model="answer_type"
                    :options="options"
                    :searchable="false"
                    :show-labels="false"
                    @input="handleAnswerTypeInput"
                >
                    <template slot="option" slot-scope="{ option }">
                        <div class="f-14 option d-flex justify-content-between">
                            <div>
                                {{ option.text }}
                            </div>
                            <div v-if="option.icon">
                                <component :is="option.icon"></component>
                            </div>
                        </div>
                    </template>

                    <template slot="singleLabel" slot-scope="{ option }">
                        <div class="d-flex justify-content-between align-items-center px-1 f-14">
                            <div v-if="option.text">
                                {{ option.text }}
                            </div>
                            <div v-if="option.icon" class="answer-type-icon">
                                <component :is="option.icon"></component>
                            </div>
                        </div>
                    </template>
                </Multiselect>
            </div>
        </div>

        <!-- Answer type settings -->
        <div class="row">
            <!-- Blank Space -->
            <div v-if="data.answer_type === 'blank'" class="col-12 d-flex flex-column">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="font-weight-bold f-11 text-muted mb-0">Answer Height</label>
                    <span class="f-10 text-muted text-nowrap">{{ Math.round(itemData.answer_height * 10) / 10 }} in</span>
                </div>
                <b-form-input
                    v-model="itemData.answer_height"
                    type="range"
                    min="0"
                    max="8"
                    step="0.01"
                    @input="updateData"
                ></b-form-input>
            </div>

            <!-- Number of Lines -->
            <div v-if="isLinesAnswerFormat" class="col-12 mb-2">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="font-weight-bold f-11 text-muted mb-0">Number of Lines</label>
                    <span class="f-11 text-muted">
                        {{ itemData.number_of_lines }}
                    </span>
                </div>
                <b-form-input v-model="itemData.number_of_lines" type="range" min="1" max="30"></b-form-input>
            </div>

            <!-- Line Height -->
            <div v-if="isLinesAnswerFormat" class="col-7">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="font-weight-bold f-11 text-muted mb-0">Line Height</label>
                    <span class="f-10 text-muted text-nowrap pl-1">{{ itemData.line_height }} in</span>
                </div>
                <b-form-input v-model="lineHeight" type="range" min="0.2" max="2" step="0.01"></b-form-input>
            </div>

            <!-- Short Line -->
            <div v-if="data.answer_type === 'single'" class="col-7 d-flex flex-column">
                <label class="mb-0 f-11 text-muted d-flex justify-content-between">
                    <div class="font-weight-bold">Line Width</div>
                    <div class="text-secondary">{{ data.line_length }} in</div>
                </label>
                <b-form-input v-model="lineLength" type="range" min="0.2" max="7.2" step="0.1"></b-form-input>
            </div>

            <!-- Line Position -->
            <div v-if="hasLinePosition" class="col-5">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="font-weight-bold f-11 text-muted mb-0">Line Position</label>
                </div>
                <div class="d-flex flex-row justify-content-between">
                    <b-form-radio v-model="linePosition" name="line_position" value="below" @change="updateData">
                        <div class="f-11 text-muted line-position-option">Under</div>
                    </b-form-radio>
                    <b-form-radio v-model="linePosition" name="line_position" value="after" @change="updateData">
                        <div class="f-11 text-muted line-position-option">After</div>
                    </b-form-radio>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { debounce } from 'lodash'
import OpenResponseItemForm from '../../widgets/item-forms/open-response-item-form.vue'
import Multiselect from 'vue-multiselect'
import IconHandwriting from '../../stories/components/icons/IconHandwriting.vue'
import IconShortLine from '../../stories/components/icons/IconShortLine.vue'
import IconStandardLines from '../../stories/components/icons/IconStandardLines.vue'
import IconBlankSpace from '../../stories/components/icons/IconBlankSpace.vue'
import { stripHtmlTags } from '../../helpers/stringUtils'

export default defineComponent({
    name: 'OpenResponse',
    components: {
        OpenResponseItemForm,
        Multiselect,
        IconHandwriting,
        IconShortLine,
        IconStandardLines,
        IconBlankSpace,
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            itemData: {
                ...this.data,
            },
            options: [
                { value: 'standard_lines', text: 'Standard Lines', icon: 'IconStandardLines' },
                { value: 'single', text: 'Short Line', icon: 'IconShortLine' },
                { value: 'blank', text: 'Blank Space', icon: 'IconBlankSpace' },
                { value: 'handwriting', text: 'Handwriting Lines', icon: 'IconHandwriting' },
            ],
            answer_type: null,
            shortLinePosition: 'after',
            standardLinePosition: 'below',
        }
    },
    computed: {
        lineHeight: {
            get() {
                return this.itemData.line_height
            },
            set(value) {
                this.itemData.line_height = value
                this.updateData()
            },
        },
        lineLength: {
            get() {
                return this.itemData.line_length
            },
            set(value) {
                this.itemData.line_length = value
                this.updateData()
            },
        },
        isLinesAnswerFormat() {
            return ['standard_lines', 'handwriting'].includes(this.itemData.answer_type)
        },
        hasLinePosition() {
            return ['standard_lines', 'single'].includes(this.itemData.answer_type)
        },
        linePosition: {
            get() {
                if (this.itemData.answer_type === 'single') return this.shortLinePosition
                return this.standardLinePosition
            },
            set(value) {
                if (this.itemData.answer_type === 'single') this.shortLinePosition = value
                else this.standardLinePosition = value

                this.itemData.line_position = value
                this.updateData()
            },
        },
    },
    watch: {
        'data.answer_type': {
            deep: true,
            immediate: true,
            handler(value) {
                if (value === 'lines') {
                    this.handleDocumentDataStructureChanges()
                }

                if (value === 'single') {
                    this.itemData.line_position = this.shortLinePosition
                } else if (value === 'standard_lines') {
                    this.itemData.line_position = this.standardLinePosition
                }
            },
        },
        'itemData.number_of_lines': {
            deep: true,
            handler() {
                this.updateData()
            },
        },
    },
    mounted() {
        if (this.itemData.answer_type) {
            this.selectAnswerType()
        }
    },
    methods: {
        updateData: debounce(function () {
            this.$emit('change', this.itemData)
        }, 100),
        handleInput(data) {
            this.itemData.subtitle = data.subtitle
            this.itemData.answer = data.answer
            this.itemData.handwritingAnswer = data.handwritingAnswer

            this.updateData()
        },
        handleAnswerTypeInput() {
            if (!this.answer_type) {
                this.selectAnswerType()
                return
            }

            this.itemData.answer_type = this.answer_type.value
            this.updateData()
        },
        selectAnswerType() {
            this.answer_type = this.options.find((item) => item.value === this.itemData.answer_type)
        },
        handleDocumentDataStructureChanges() {
            if (this.itemData.line_style === 'handwriting') {
                this.itemData.answer_type = 'handwriting'
                this.itemData.answer = this.itemData.handwritingAnswer ? this.itemData.handwritingAnswer : ''
            } else {
                this.itemData.answer_type = 'standard_lines'
                this.itemData.handwritingAnswer = this.itemData.answer ? stripHtmlTags(this.itemData.answer) : ''
            }

            this.selectAnswerType()
            this.updateData()
        },
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';
@import 'vue-multiselect/dist/vue-multiselect.min.css';
@import 'Scss/runtime.scss';

.input-header {
    min-height: 37px;
}

.answer-type {
    .multiselect {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        max-height: 30px;
        min-height: initial;

        .multiselect__select {
            position: relative;
            min-height: initial;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;

            &:before {
                top: 0;
                color: #000;
                margin-top: 0px;
                border-color: #000 transparent transparent;
            }
        }

        .multiselect__tags {
            min-height: initial;
            flex-grow: 1;
            padding: 0;
            border: none;
            display: flex;
            align-items: center;

            .multiselect__single {
                height: 100%;
                min-height: initial;
                display: block;
                overflow: hidden;
                background: #fff;
                margin-bottom: 0px;
            }
        }

        .multiselect__option--highlight {
            background: $primary;
        }

        .multiselect__option--selected {
            background: initial;
            color: inherit;
            font-weight: normal;

            &.multiselect__option--highlight {
                background: $primary;
                color: $white;
            }
        }
    }
}

.multiselect__content-wrapper {
    border-radius: 5px;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}

.answer-type .multiselect--disabled .multiselect__tags .multiselect__single {
    height: 100%;
    min-height: initial;
    display: block;
    overflow: hidden;
    background: #f1f1f1;
    margin-bottom: 0px;
}

.answer-type .multiselect--disabled .multiselect__tags .multiselect__single {
    height: 100%;
    min-height: initial;
    display: block;
    overflow: hidden;
    background: #f1f1f1;
    margin-bottom: 0px;
}

.answer-type-icon {
    line-height: 0 !important;
}

.line-position-option {
    height: 1.5rem;
    line-height: 1.5rem;
}
</style>
