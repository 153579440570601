<template>
    <div class="card-body pb-2 pt-0">
        <div>
            <div class="d-flex justify-content-between align-items-center mb-2">
                <b-form-checkbox
                    id="title_visible"
                    v-model="title_visible"
                    class="subtitle font-weight-bold text-muted"
                    name="title_visible"
                    value="1"
                    unchecked-value="0"
                >
                    {{ titleCheckboxLabel }}
                </b-form-checkbox>

                <ToggleButton
                    v-model="showStyles"
                    v-b-tooltip.hover
                    size="sm"
                    aria-label="Toggle Title and Student Info Styles"
                    title="Style options"
                >
                    <IconPalette class="mr-1" />
                    <IconA />
                </ToggleButton>
            </div>
            <div v-if="loading && !isWorksheet" class="d-flex justify-content-center align-items-center min-h-76px">
                <Loader :dark="true" size="30px"></Loader>
            </div>

            <div v-else class="min-h-76px input-box position-relative" @drop="(e) => e.preventDefault()">
                <div class="quillWrapper">
                    <div id="toolbar">
                        <span class="ql-formats">
                            <button class="ql-bold" aria-label="Bold"></button>
                            <button class="ql-italic" aria-label="Italic"></button>
                            <button class="ql-underline" aria-label="Underline"></button>
                        </span>
                    </div>
                </div>
                <VueEditor
                    ref="worksheet_header"
                    v-model="titleContent"
                    :editor-options="editorSettings"
                    :placeholder="titlePlaceholder ? titlePlaceholder : ''"
                    @focus="selectAll"
                    @text-change="onTextChange"
                ></VueEditor>
            </div>
            <div v-if="showStyles" class="row w-100 m-0 my-2 align-items-baseline">
                <div class="col-md-6 p-0 d-flex flex-column mt-1">
                    <label class="font-weight-bold mb-1 f-11 text-muted">Font Type</label>
                    <div>
                        <FontSelect
                            id="header-font"
                            ref="font-select"
                            :key="'header-font'"
                            class="mt-0 mb-0 pb-0 w-100"
                            field="title_font"
                            :options="false"
                            size="sm"
                        />
                    </div>
                </div>
                <div class="col-md-3 p-0 pl-3 d-flex flex-column">
                    <ColorPicker v-model="title_color" label="Font Color" />
                </div>
                <div class="col-md-3 p-0 pl-3 d-flex flex-column mt-1">
                    <label class="mb-1 f-11 text-muted d-flex justify-content-between">
                        <span class="font-weight-bold text-nowrap">Font Size</span>
                        <span class="text-secondary">
                            {{ parseInt(document.title_font_size) }}
                        </span>
                    </label>
                    <div class="d-flex f-10 align-items-center mb-0 mt-1">
                        <b-form-input
                            v-model="title_font_size"
                            class="flex-glow-1"
                            type="range"
                            min="6"
                            max="64"
                            :value="document.title_font_size"
                        ></b-form-input>
                    </div>
                </div>
            </div>
        </div>
        <hr class="my-1" />
        <div>
            <div class="row mx-0 mt-0 mb-2 input-box">
                <!-- TODO: check if we can remove subtitle class from checkboxes-->
                <b-form-checkbox
                    id="checkbox-2"
                    v-model="student_info_visible"
                    class="flex-grow-1 subtitle font-weight-bold text-muted"
                    name="checkbox-2"
                    value="1"
                    unchecked-value="0"
                >
                    Student Info
                </b-form-checkbox>
                <div
                    v-if="isWorksheet || isFlashcard"
                    v-b-tooltip.hover
                    title="Show student info at the top of every page"
                    class="col-md-6 d-flex align-items-center p-0 justify-content-end"
                >
                    <label class="f-11 font-weight-bold text-muted mb-0 cursor-pointer" for="every_page">Every Page</label>
                    <b-form-checkbox
                        id="every_page"
                        v-model="omniStudent"
                        class="f-13 ml-1"
                        name="check-button"
                        value="1"
                        unchecked-value="0"
                        switch
                        :disabled="disableEveryPage"
                    ></b-form-checkbox>
                </div>
            </div>
            <StudentForm />
            <div v-if="showStyles" class="row w-100 m-0 my-2 align-items-baseline">
                <div class="col-md-6 p-0 d-flex flex-column">
                    <label class="font-weight-bold mb-1 f-11 text-muted">Font Type</label>
                    <div>
                        <FontSelect
                            id="student-info-title"
                            :key="'student-info-font' + document.student_info_font"
                            class="mt-0 mb-0 pb-0 w-100"
                            field="student_info_font"
                            :options="false"
                            size="sm"
                        />
                    </div>
                </div>
                <div class="col-md-3 p-0 pl-3 d-flex flex-column">
                    <ColorPicker v-model="student_info_color" label="Font Color" />
                </div>
                <div class="col-md-3 p-0 pl-3 d-flex flex-column">
                    <label class="mb-1 f-11 text-muted d-flex justify-content-between">
                        <span class="font-weight-bold text-nowrap">Font Size</span>
                        <span class="text-secondary">
                            {{ parseInt(document.student_info_font_size) }}
                        </span>
                    </label>
                    <div class="d-flex f-10 align-items-center mb-0 mt-1">
                        <b-form-input
                            class="flex-glow-1"
                            type="range"
                            min="6"
                            max="48"
                            :value="document.student_info_font_size"
                            @input="
                                $store.dispatch('document/setDocument', {
                                    student_info_font_size: $event,
                                })
                            "
                            @change="persistFontSize"
                        ></b-form-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { VueEditor } from 'vue2-editor'
import StudentForm from '../student-form.vue'
import { mapGetters, mapState } from 'vuex'
import ToggleButton from '../ToggleButton.vue'
import StatsigEvent from '../../common/StatsigEvent'
import { filenameFromTitle } from '../../common/helpers'
import FontSelect from '../../widgets/font-select.vue'
import ColorPicker from '../ColorPicker.vue'
import Loader from '../../widgets/Loader.vue'
import IconPalette from '../../stories/components/icons/IconPalette.vue'
import IconA from '../../stories/components/icons/IconA.vue'
import {
    bingoDefaultTitleOption,
    bingoDefaultTitles,
    flashcardDefaultTitleOption,
    flashcardDefaultTitles,
    worksheetDefaultTitleOption,
    worksheetDefaultTitles,
} from '../../store/helpers/documentHelpers'

const getTitlePlaceHolder = (entity_type = null) => {
    let placeholder = 'Add a title!'
    switch (entity_type) {
        case 'worksheet':
            placeholder = 'Give your worksheet a title!'
            break
        case 'flashcard':
            placeholder = 'Give your flashcards a title!'
            break
        default:
            break
    }
    return placeholder
}

export default defineComponent({
    name: 'WorksheetHeader',
    components: {
        IconPalette,
        IconA,
        ColorPicker,
        VueEditor,
        StudentForm,
        ToggleButton,
        FontSelect,
        Loader,
    },
    data() {
        return {
            showWorksheetTitle: true,
            showStudentInfoStyles: false,
            showTitleStyles: false,
            showStudentInfo: true,
            content: '',
            editorSettings: {
                imageDrop: false,
                formats: ['bold', 'italic', 'underline', 'script'],
                modules: {
                    toolbar: '#toolbar',
                },
            },
            titlePlaceholder: null,
            updateInterval: null,
            titleMaxLength: 300,
            showStyles: false,
        }
    },
    computed: {
        ...mapState(['document', 'user']),
        ...mapGetters({
            style: 'document/documentStyle',
            omni_student: 'document/omniStudent',
            isLoggedIn: 'user/isLoggedIn',
            isWorksheet: 'document/isWorksheet',
            isFlashcard: 'document/isFlashcard',
            entityType: 'document/entityType',
            currentWidget: 'document/currentWidget',
            flashcard: 'document/flashcard',
            hasHeaderStylesFeature: 'abtests/headerStyles',
            loadingAbs: 'abtests/loading',
            maxLength: 'document/filenameMaxLength',
            allDocumentTypes: 'document/allDocumentTypes',
            defaultTitle: 'abtests/defaultTitle',
            bingoDefaultTitle: 'abtests/bingoDefaultTitle',
            flashcardDefaultTitle: 'abtests/flashcardDefaultTitle',
            worksheetHeaderAndTitleLabelCombination: 'abtests/worksheetHeaderAndTitleLabelCombination',
            defaultDocumentTitle: 'document/defaultDocumentTitle',
            titleUpdated: 'document/titleUpdated',
        }),
        titleContent: {
            get() {
                return this.content
            },
            async set(value) {
                this.content = value
                await this.$store.dispatch('document/setDocument', { titleUpdated: true })
                if (this.content !== this.document.title) {
                    let doc = { title: this.content }
                    const filename = filenameFromTitle(this.content, this.document.entity_type)
                    if (!this.document.filenameEdited && filename !== this.defaultDocumentTitle) {
                        doc.filename = filename
                    }
                    await this.$store.dispatch('document/setDocument', doc)
                    this.$nextTick(() => {
                        this.persistHeaderChange()
                    })
                }
                window.doc.title = this.content
            },
        },
        omniStudent: {
            get() {
                return this.omni_student
            },
            set(value) {
                this.$store.dispatch('document/setOmniStudent', parseInt(value))
            },
        },
        title_visible: {
            get() {
                return this.document.title_visible
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    title_visible: Number(value),
                })

                this.$emit('change', {
                    non_item: true,
                })
            },
        },

        student_info_visible: {
            get() {
                return this.document.student_info_visible
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    student_info_visible: Number(value),
                })

                this.$emit('change', {
                    non_item: true,
                })
            },
        },

        title_font: {
            get() {
                return this.$store.state.document.title_font
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    title_font: value,
                })

                this.$emit('change', {
                    non_item: true,
                })
            },
        },

        title_color: {
            get() {
                return this.$store.state.document.title_color
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    title_color: value,
                })

                this.$emit('change', {
                    non_item: true,
                })
            },
        },

        student_info_color: {
            get() {
                return this.$store.state.document.student_info_color
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    student_info_color: value,
                })

                this.$emit('change', {
                    non_item: true,
                })
            },
        },

        title_font_size: {
            get() {
                return this.$store.state.document.title_font_size
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    title_font_size: value,
                })

                this.$emit('change', {
                    non_item: true,
                })
            },
        },
        flashcardHeaderHeight() {
            return this.document.flashcardHeaderHeight
        },
        disableEveryPage() {
            return 10 - this.flashcardHeaderHeight < this.flashcard.flashcard_item_height
        },
        showStyleRow() {
            return !this.loadingAbs && !this.hasHeaderStylesFeature
        },
        loading() {
            return !this.isLoggedIn && this.loadingAbs
        },
        hasSetTitle() {
            const title = this.content.replace(/(<([^>]+)>)/gi, '')

            switch (this.document.entity_type) {
                case 'bingo':
                    return !bingoDefaultTitles.includes(title)
                case 'flashcard':
                    return !flashcardDefaultTitles.includes(title)
                default:
                    return !worksheetDefaultTitles.includes(title)
            }
        },
        titleCheckboxLabel() {
            return this.isWorksheet ? this.worksheetHeaderAndTitleLabelCombination.title : 'Title'
        },
        shouldSetDefaultTitle() {
            return !this.document.title && !this.titleUpdated && !this.document.id && !window.doc.copy_of_document_id
        },
    },
    watch: {
        'document.title': {
            deep: true,
            handler(value) {
                if (value) {
                    this.content = value
                    return
                }

                if (!this.shouldSetDefaultTitle) return

                switch (this.entityType) {
                    case 'bingo':
                        this.titleContent = this.bingoDefaultTitle //|| bingoDefaultTitleOption
                        break
                    case 'flashcard':
                        this.titleContent = this.flashcardDefaultTitle //|| flashcardDefaultTitleOption
                        break
                    default:
                        this.titleContent = worksheetDefaultTitleOption
                        break
                }
            },
        },
        async loadingAbs(current) {
            if (current) return

            if (!this.isLoggedIn && !this.content) {
                switch (this.entityType) {
                    case 'bingo':
                        await this.$store.dispatch('abtests/loadBingoDefaultTitleOptionTests')
                        break
                    case 'flashcard':
                        await this.$store.dispatch('abtests/loadFlashcardsDefaultTitleOptionTests')
                        break
                }
            }

            await this.$store.dispatch('document/setTitle')
            this.$nextTick(() => {
                this.selectAll()
            })
        },
        currentWidget: {
            immediate: true,
            handler(val) {
                if (val.openHeader)
                    this.$nextTick(() => {
                        if (val.openHeader) this.selectAll()
                    })
            },
        },
        showStudentInfoStyles() {
            this.toggleStudentInfoStyles()
        },
        showTitleStyles() {
            this.toggleTitleStyles()
        },
        showStyles() {
            this.toggleTitleStyles()
            this.toggleStudentInfoStyles()
        },
    },
    created() {
        this.content = this.shouldSetDefaultTitle ? this.defaultDocumentTitle : this.document.title
        this.titlePlaceholder = getTitlePlaceHolder(this.document?.entity_type)
    },
    mounted() {
        if (this.entityType === 'bingo') {
            this.persistHeaderChange(true)
        }
    },
    methods: {
        selectAll() {
            const quill = this.$refs.worksheet_header?.quill
            if (!quill) return

            if (!this.content || this.content.length === 0 || this.hasSetTitle) {
                this.$nextTick(() => {
                    quill.focus()
                })
                return
            }
            this.$nextTick(() => {
                quill.setSelection(0, this.content.length)
            })
        },
        toggleTitleStyles() {
            let title = 'Header Title Styles ' + (!this.showTitleStyles ? 'Hidden' : 'Shown')
            this.logStyleButtonClick(title)
        },
        toggleStudentInfoStyles() {
            let title = 'Student Info Styles ' + (!this.showStudentInfoStyles ? 'Hidden' : 'Shown')
            this.logStyleButtonClick(title)
        },
        logStyleButtonClick(title = null) {
            if (!this.hasHeaderStylesFeature) return
            this.$store.dispatch('abtests/logStatsigEvent', {
                event: StatsigEvent.HEADER_STYLES_BTN_CLICK,
                value: 'Has-Used Feature',
                payload: {
                    title: title ?? 'Header styles shown',
                },
            })
        },
        persistFontSize() {
            this.$store.dispatch('document/storeDocumentState')
            this.$nextTick(() => {
                this.$store.dispatch('document/scaleDocument', true)
            })
        },
        persistHeaderChange(immediate = false) {
            if (!this.content) return
            if (this.updateInterval) clearInterval(this.updateInterval)

            let interval = immediate ? 1 : 500

            this.updateInterval = setInterval(() => {
                if (this.document.filename !== '') {
                    this.$emit('change', {
                        non_item: true,
                    })
                }
                clearInterval(this.updateInterval)
            }, interval)
        },
        onTextChange() {
            const quill = this.$refs.worksheet_header.quill
            const len = quill.getLength()
            if (len > this.titleMaxLength) {
                quill.deleteText(this.titleMaxLength, len)
            }
        },
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';

.subtitle {
    font-size: $font-size-sm;
}

.input-box {
    .toggle-btn {
        right: 12px;
        top: 5px;

        svg {
            width: 16px;
        }
    }
}

.min-h-76px {
    min-height: 76px;
}

#toolbar {
    border-bottom: none;
}
</style>
