<template>
    <div class="student-form">
        <div ref="itemsHolder" class="items-holder">
            <Draggable v-model="studentFields" v-bind="{ handle: '.wordbank-word-handle', animation: 500 }">
                <b-input-group
                    v-for="(item, index) in studentFields"
                    :key="index"
                    class="mb-1 multi-option-handle"
                    size="sm"
                >
                    <b-input-group-prepend>
                        <b-button
                            :id="'si-handle-' + index"
                            class="wordbank-word-handle"
                            size="sm"
                            variant="secondary"
                            tabindex="-1"
                            style="cursor: grab"
                            @click.prevent=""
                            @keyup.up="moveItemUp(index)"
                            @keyup.down="moveItemDown(index)"
                        >
                            <b-icon icon="grip-horizontal"></b-icon>
                        </b-button>
                    </b-input-group-prepend>

                    <b-form-input
                        :id="'studentField' + index"
                        ref="student-field"
                        type="text"
                        class="flex-grow-1"
                        placeholder="Name, Date, Class etc."
                        :value="item"
                        @input="
                            $store.dispatch('document/setStudentField', {
                                index: index,
                                value: $event,
                            })
                        "
                        @change="$store.dispatch('document/storeDocumentState')"
                    />

                    <b-input-group-append>
                        <b-button
                            variant="danger"
                            tabindex="-1"
                            style="z-index: 0"
                            aria-label="Remove field"
                            @click.prevent="removeItem(index)"
                        >
                            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" height="20" width="20">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                ></path>
                            </svg>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </Draggable>
        </div>

        <b-input
            v-if="!shouldLimitStudentFields"
            v-model="newStudentField"
            type="text"
            size="sm"
            list="add-new-student-field"
            placeholder="Add New Field"
            autocomplete="off"
        />
        <datalist id="add-new-student-field">
            <option v-for="(f, fIndex) in studentFieldOptions" :key="'studentFormDatalist-' + fIndex">
                {{ f }}
            </option>
        </datalist>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Draggable from 'vuedraggable'
import { mapGetters, mapState } from 'vuex'
import { copyObject } from '../helpers/copyObject'
import WithDocumentHeader from '../mixins/WithDocumentHeader'

export default defineComponent({
    name: 'StudentForm',
    components: {
        Draggable,
    },
    mixins: [WithDocumentHeader],
    data() {
        return {
            newStudentField: '',
            suggestedFields: ['Name', 'Date', 'Class', 'Grade', 'Teacher', 'Subject', 'Period', 'Section', 'Age'],
        }
    },
    computed: {
        ...mapState(['document']),
        ...mapGetters({
            currentWidget: 'document/currentWidget',
            isFlashcard: 'document/isFlashcard',
        }),
        studentFieldOptions() {
            let availableOptions = []
            for (let i = 0; i < this.suggestedFields.length; i++) {
                let val = this.suggestedFields[i]
                if (
                    !this.document.student_fields
                        .map((field) => (typeof field === 'string' ? field.toLowerCase() : field))
                        .includes(val.toLowerCase())
                ) {
                    availableOptions.push(val)
                }
            }
            return availableOptions
        },
    },
    watch: {
        newStudentField(val) {
            if (val.trim()) {
                this.addNewField(val.trim())
            }
        },
        currentWidget: {
            immediate: true,
            handler(val) {
                if (val.openStudentInfo) {
                    setTimeout(() => {
                        document.getElementById('studentField' + val.focusedItem.index).focus()
                    }, 100)
                }
            },
        },
    },
    created() {
        //non-reactive property
        this.tabIndex = false
    },
    methods: {
        moveItemUp(index) {
            let fields = copyObject(this.studentFields)
            let newIndex
            if (index) {
                newIndex = index - 1
            } else {
                newIndex = fields.length - 1
            }

            //get the item from its current index
            let item = fields.splice(index, 1)[0]
            //place it in it's new index
            fields.splice(newIndex, 0, item)

            //write over the original student fields with our new one.
            this.studentFields = fields
            this.$nextTick(() => {
                document.getElementById('si-handle-' + newIndex).focus()
            })
        },
        moveItemDown(index) {
            let fields = copyObject(this.studentFields)

            let newIndex
            if (index === fields.length - 1) {
                newIndex = 0
            } else {
                newIndex = index + 1
            }

            //get the item from its current index
            let item = fields.splice(index, 1)[0]
            //place it in it's new index
            fields.splice(newIndex, 0, item)

            //write over the original student fields with our new one.
            this.studentFields = fields

            this.$nextTick(() => {
                document.getElementById('si-handle-' + newIndex).focus()
            })
        },
        fieldUsed(field) {
            return this.document.student_fields.includes(field)
        },
        addNewField(value) {
            this.$store.dispatch('document/pushStudentField', value)
            this.$nextTick(() => {
                let lastElemIndex = this.$refs['student-field'].length - 1
                this.$refs['student-field'][lastElemIndex].focus()
                this.newStudentField = ''
                this.$store.dispatch('document/scaleDocument', true)
            })
        },
        removeItem(index) {
            this.$store.dispatch('document/removeStudentField', index)
            this.$store.dispatch('document/storeDocumentState')
        },
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';
// TODO: Uncomment this when legacy scss functions have been removed.
// @import 'Scss/runtime.scss';

.student-form {
    .input-group-sm > .form-control:not(textarea) {
        height: calc(1.5em + 0.5rem + 5px);
    }
    input:not(:placeholder-shown)::-webkit-calendar-picker-indicator {
        display: none;
    }
    .items-holder {
        $button-size: 24px;

        .handle {
            cursor: hand;
            cursor: grab;
        }

        .handle-button,
        .delete-button {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: $button-size;
            height: $button-size;
            color: $white;
            border-radius: 50% !important;
            z-index: 2;
            top: -($button-size * 0.5);
            border: 0;
        }
        .handle-button {
            background-color: $primary;
            left: -($button-size * 0.5);

            &:hover,
            &:active {
                background-color: darken($primary, 15%);
            }
        }
        .delete-button {
            background-color: $danger;
            right: -($button-size * 0.5);

            &:hover,
            &:active {
                background-color: darken($danger, 15%);
            }
        }
    }
    .sortable-chosen,
    .sortable-ghost {
        .handle {
            cursor: hand !important;
            cursor: grabbing !important;
        }
    }
}
</style>
