import { defineComponent } from 'vue'

interface AddItemWidgetElements {
    widget: HTMLElement
    newwidget: HTMLElement
}

export default defineComponent({
    name: 'WithAddItem',
    methods: {
        async hideAddItemsAndButtons(uniqueRef: string | null = null): Promise<void> {
            document.querySelectorAll<HTMLElement>('[id^="add_widgets_item_"]').forEach((widget: HTMLElement) => {
                widget.style.display = 'none'
            })

            await this.$store.dispatch('document/setActiveAddItem', null)

            document.querySelectorAll<HTMLElement>('[id^="add_widgets_button_"]').forEach((widget: HTMLElement) => {
                if (widget) {
                    widget.style.display = 'block'
                    widget.style.visibility = 'hidden'
                }
            })

            if (uniqueRef != null) {
                const widget = document.getElementById('add_widgets_item_' + uniqueRef) as HTMLElement
                const newwidgetBtn = document.getElementById('add_widgets_button_' + uniqueRef) as HTMLElement

                if (widget) widget.style.display = 'none'
                if (newwidgetBtn) {
                    newwidgetBtn.style.visibility = 'hidden'
                    newwidgetBtn.style.display = 'block'
                }
            }
        },
        getWidgetItems(uniqueRef: string): AddItemWidgetElements {
            return {
                widget: document.getElementById('add_widgets_item_' + uniqueRef) as HTMLElement,
                newwidget: document.getElementById('add_widgets_button_' + uniqueRef) as HTMLElement,
            }
        },
        hideAllAddWidgetButtons(): void {
            const addWidgetButtons = document.querySelectorAll<HTMLElement>('[id^="add_widgets_button_"]')
            addWidgetButtons.forEach((widget: HTMLElement) => {
                widget.style.visibility = 'hidden'
            })
        },
        async showAddWidgetByUniqueRef(uniqueRef: string): Promise<void> {
            const widget = document.getElementById(`add_widgets_item_${uniqueRef}`) as HTMLElement
            const newWidgetBtn = document.getElementById(`add_widgets_button_${uniqueRef}`) as HTMLElement

            await this.$store.dispatch('document/setActiveAddItem', uniqueRef)

            if (widget) widget.style.display = 'block'
            if (newWidgetBtn) {
                newWidgetBtn.style.visibility = 'hidden'
                newWidgetBtn.style.display = 'none'
            }
        },
        async showLastAddItemWidget(): Promise<void> {
            const addItemWidgets = document.querySelectorAll<HTMLElement>('[id^="add_widgets_item_"]')
            const lastWidget = addItemWidgets[addItemWidgets.length - 1]
            if (lastWidget) {
                lastWidget.style.visibility = 'visible'
                lastWidget.style.display = 'block'

                let id = lastWidget.id
                if (!id.includes('add_widgets_item_')) return

                id = id.replace('add_widgets_item_', '')
                await this.$store.dispatch('document/setActiveAddItem', id)
            }
        },
    },
})
