<template>
    <div class="blank-space position-relative thisShow">
        <figure
            v-if="heightNumber > 0"
            class="mb-0 border-style"
            :class="[...noShowStyle, ...blankSpaceBorderStyles]"
            :style="{ height: height + 'in' }"
        >
            <div
                class="d-block position-absolute"
                :class="[...noShowStyle, ...blankSpaceStyles]"
                :style="{ '--heightPseudo': heightNumber / 5 + 'in' }"
            >
                <div :style="lineStyle"></div>
            </div>
        </figure>
    </div>
</template>

<script lang="ts">
import { defineComponent, type CSSProperties } from 'vue'

export default defineComponent({
    name: 'BlankSpaceIndicator',
    props: {
        height: {
            type: [Number, String],
            required: true,
        },
        hide: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        document(): any {
            return this.$store.state.document as any
        },
        heightNumber(): number {
            if (typeof this.height === 'number') return this.height
            return parseFloat(this.height)
        },
        noShowStyle(): string[] {
            let response: string[] = []
            if (this.document.is_published || this.hide) response.push('no-show')
            if (this.heightNumber <= 0.35) response.push('no-show')
            return response
        },
        blankSpaceBorderStyles(): string[] {
            let response: string[] = []
            if (this.noShowStyle.includes('no-show')) return response
            if (this.heightNumber <= 1.2) response.push('border-style')
            if (this.heightNumber > 1.2 && this.heightNumber <= 2) response.push('border-style-middle')
            if (this.heightNumber >= 2) response.push('border-style-large')

            return response
        },
        blankSpaceStyles(): string[] {
            let response: string[] = []
            if (this.noShowStyle.includes('no-show')) return response
            if (this.heightNumber <= 1.2) response.push('addingBefore')
            if (this.heightNumber > 1.2 && this.heightNumber <= 2) response.push('addingAgainBefore')
            if (this.heightNumber >= 2) response.push('addingLastBefore')

            return response
        },
        lineStyle(): CSSProperties {
            return {
                marginLeft: `2.5px`,
                height: `4px`,
                width: `20px`,
                borderBottom: `4px solid #cacaca`,
                borderRadius: `5px`,
            }
        },
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';

.border-style {
    font-size: 15px !important;
    line-height: 1.5 !important;
}

.border-style::before,
.border-style::after,
.border-style-middle::after,
.border-style-large::after {
    position: absolute;
    display: flex;
    width: 100px;
    left: -1.7rem;
    color: $blank-space-border-color;
    transform: rotate(90deg);
}

.border-style::before {
    content: '';
    transform: none;
    border-left: 3px dashed $blank-space-border-color !important;
    left: 0.7rem;
    height: 100%;
    z-index: -10;
}

.border-style::after {
    content: '';
    bottom: 1.5rem;
    height: 0;
}

.border-style-middle::after {
    content: 'BLANK';
    bottom: 2.5rem;
    height: 0;
}

.border-style-large::after {
    content: 'BLANK SPACE';
    bottom: 5.5rem;
    height: 0;
}

.addingBefore,
.addingAgainBefore,
.addingLastBefore {
    bottom: -0.3rem;
}

.addingBefore::after,
.addingAgainBefore::after,
.addingLastBefore::after {
    content: '';
    position: absolute;
    background: url('../../img/icons/blank_border.svg') no-repeat 0 100% white;
    background-size: auto;
    left: 0.1rem;
    width: 21px;
    display: flex;
    height: var(--heightPseudo);
}

.addingBefore::after {
    bottom: 1rem;
    padding-top: 2rem;
}

.addingAgainBefore::after {
    bottom: 1rem;
    padding-top: 5.3rem;
}

.addingLastBefore::after {
    bottom: 1rem;
    padding-top: 8.3rem;
}

.no-show {
    opacity: 0;
}

.blank-space {
    min-height: 0.125in;
    left: -0.2rem;
    img {
        vertical-align: baseline;
    }
}
</style>
