import { AxiosResponse } from 'axios'
import apiClient from './apiClient'
import type { Browser, BrowserType } from '../objects/Browser'

let getBrowserLoading: boolean = false
let getBrowserTypeLoading: boolean = false

export const get = async (browser_id: string): Promise<AxiosResponse<any, any>> => {
    return apiClient.get('/browsers/' + browser_id)
}

export const getBrowser = async (browserId: string): Promise<Browser | undefined> => {
    if (getBrowserLoading) return

    try {
        getBrowserLoading = true
        const response = await get(browserId)
        return response.data
    } catch (e) {
        console.error(e)
    } finally {
        getBrowserLoading = false
    }
}

export const getBrowserType = async (): Promise<BrowserType | undefined> => {
    if (getBrowserTypeLoading) return

    try {
        getBrowserTypeLoading = true
        const browser = await getBrowser(window.browser_id)
        return browser?.browser_type
    } catch (e) {
        console.error(e)
    } finally {
        getBrowserTypeLoading = false
    }
}

export default {
    get,
    getBrowser,
    getBrowserType,
}
