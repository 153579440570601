<template>
    <div v-if="!item.hide" id="blank_space" class="d-flex my-auto">
        <BlankSpaceIndicator v-if="item.data.box_outline === false" :height="item.data.height" />

        <div
            v-if="item.data.box_outline === true || is_published"
            class="text-center d-flex ml-auto"
            :style="[
                {
                    height: item.data.height + 'in',
                    width: '100%',
                },
                item.data.box_outline
                    ? { border: `${item.data.border_width + 'px'} ${item.data.line_style} ${item.data.line_color}` }
                    : { border: 'none' },
            ]"
        ></div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import BlankSpaceIndicator from '../BlankSpaceIndicator.vue'

export default defineComponent({
    name: 'BlankSpacePreview',
    components: {
        BlankSpaceIndicator,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            is_published: window.doc.is_published ?? false,
        }
    },
})
</script>
