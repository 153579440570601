import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import ImageModel from '../store/models/Image'
import ImageUploadApi from '../apis/ImageUploadApi'
import { dangerToast } from '../helpers/toasts'
import { removeSearchPair, addSearchPairToLocalHistory } from '../utils/icon-search'
import WithAutoScroll from './WithAutoScroll'

interface Data {
    search: string
    isUploadingImage: boolean
    keepIconsGalleryOpen: boolean
    filetypes: string[]
}

interface Position {
    x: number
    y: number
}

export default defineComponent({
    name: 'WithImageUpload',
    mixins: [WithAutoScroll],
    data(): Data {
        return {
            search: '',
            isUploadingImage: false,
            keepIconsGalleryOpen: false,
            filetypes: ['image/jpeg', 'image/png', 'image/jpg', 'image/svg'],
        }
    },
    computed: {
        document(): any {
            return this.$store.state.document as any
        },
        ...mapGetters({
            defaultIcons: 'iconLibrary/defaultIcons',
            entityType: 'document/entityType',
            draggedImage: 'document/draggedImage',
            zoomRatio: 'document/getScaleRatio',
            persistAction: 'document/immediatePersistAction',
            isLoggedIn: 'user/isLoggedIn',
            isInlineImage: 'document/isInlineImage',
            loadingImage: 'document/loadingImage',
            showImageUploader: 'document/showImageUploader',
        }),
        show: {
            get(): boolean {
                return this.showImageUploader
            },
            async set(value: boolean) {
                await this.$store.dispatch('document/setShowImageUploader', value)
                await this.$store.dispatch('document/setIsImageUploaderOpen', value)
            },
        },
    },
    mounted() {
        this.addEventListeners()
    },
    methods: {
        async setLoadingImage(value: boolean) {
            await this.$store.dispatch('document/setLoadingImage', value)
        },
        closeIconsGallery(): void {
            this.keepIconsGalleryOpen = false
        },
        async close(): Promise<void> {
            if (!this.loadingImage) await this.$store.dispatch('document/setIsInlineImage', false)
            this.closeIconsGallery()
            this.show = false
            this.$emit('visible-changed', this.show)
            await this.$store.dispatch('document/closeImageUploader')
        },
        calcViewableHeightOf(page: Element): number {
            const pageRect = page.getBoundingClientRect()
            if (pageRect.top < 0 && pageRect.bottom < 0) {
                return 0
            } else if (pageRect.top > 0) {
                return Math.min(pageRect.bottom, window.innerHeight) - pageRect.top
            } else {
                return Math.min(pageRect.bottom, window.innerHeight)
            }
        },
        currentDocumentInViewport(pages?: HTMLCollection): number {
            if (!pages) {
                pages = document.getElementsByClassName('render-page')
            }
            let maxViewableHeight = 0
            let index = 0

            for (let i = 0; i < pages.length; i++) {
                const viewableHeight = this.calcViewableHeightOf(pages[i])

                if (maxViewableHeight === 0) {
                    maxViewableHeight = viewableHeight
                    index = i
                    continue
                }

                if (viewableHeight > maxViewableHeight) return i
            }

            return index
        },
        async addNewAttachmentToDocument(attachments: ImageModel[]): Promise<void> {
            if (!attachments || attachments.length === 0) return

            for (let i = 0; i < attachments.length; i++) {
                if (!attachments[i].height) attachments[i].height = attachments[i].width * attachments[i].ratio

                attachments[i].ratio = 1
                attachments[i].lockAspectRatio = true
                attachments[i].entity = this.entityType
            }
            await this.$store.dispatch('document/setImage', attachments)
            removeSearchPair(this.document.replaceImageId)
            addSearchPairToLocalHistory(attachments[0].id, this.search)
            this.search = ''
            this.$nextTick(() => {
                this.ensureImageIsVisible(attachments[0])
            })
        },
        async updateDocumentIconImageId(payload: any): Promise<void> {
            payload.entity = this.entityType
            await this.$store.dispatch('document/setImageId', payload)
        },
        async toggleSelectedIcon(icon: any, position?: Position): Promise<void> {
            await this.setLoadingImage(true)
            const pages = document.getElementsByClassName('render-page')
            const pageIndex = this.currentDocumentInViewport(pages)
            const image = new ImageModel(this.search || this.defaultIcons, pageIndex, icon)

            try {
                const { x, y } = this.calculatePosition(image, position)

                image.x = x
                image.y = y

                await this.addNewAttachmentToDocument([{ ...image }])
                await this.close()

                const oldId = image.objectId
                const id = await ImageUploadApi.downloadIcon(icon)
                await this.updateDocumentIconImageId({ oldId, newId: id })
            } catch (error: any) {
                await this.removeFailedAttachmentFromDocument(image)
                const errorMessage = this.getErrorMessage(error)
                this.$bvToast.toast(errorMessage, dangerToast)
            } finally {
                await this.setLoadingImage(false)
                if (this.isInlineImage) await this.$store.dispatch('document/setIsInlineImage', false)
            }
        },
        async handleIconDrag($event: any, image: any): Promise<void> {
            $event.dataTransfer.effectAllowed = 'move'

            const rect = $event.target.getBoundingClientRect()
            image.offsetX = $event.clientX - rect.left
            image.offsetY = $event.clientY - rect.top

            await this.$store.dispatch('document/setDraggedImage', { image })
        },
        async handleDrop($event: any, files?: File[]): Promise<void> {
            const droppedImage = this.draggedImage
            if (!droppedImage && (!files || !files.length)) return

            const pages = document.getElementsByClassName('render-page')
            const pageIndex = this.currentDocumentInViewport(pages)
            const page = pages[pageIndex]

            const dropZoneRect = page.getBoundingClientRect()

            // Calculate position relative to the drop zone
            const relativeX = ($event.clientX - dropZoneRect.left) / dropZoneRect.width
            const relativeY = ($event.clientY - dropZoneRect.top) / dropZoneRect.height

            // Convert relative position to actual coordinates
            const x = Math.max(1, Math.round(relativeX * page.offsetWidth))
            const y = Math.max(1, Math.round(relativeY * page.offsetHeight))

            const position = { x, y }

            await this.$store.dispatch('document/clearDraggedImage')

            if (files && files.length) {
                await this.uploadFiles(files, position)
            } else if (droppedImage) {
                await this.toggleSelectedIcon(droppedImage, position)
            }
        },
        ensureImageIsVisible(image: ImageModel): void {
            const maxAttempts = 5
            let attempts = 0

            const tryFindImage = () => {
                const imageElement = document.getElementById(image.id)
                const anchorElement = document.getElementById(`image-anchor-${image.id}`)

                if (imageElement) {
                    const rect = imageElement.getBoundingClientRect()
                    const anchorElementId = anchorElement ? `image-anchor-${image.id}` : image.id

                    if (
                        rect.top < this.stickyHeaderHeight ||
                        rect.top > window.innerHeight ||
                        rect.bottom > window.innerHeight
                    ) {
                        this.scrollIntoView(anchorElementId)
                    }
                } else if (attempts < maxAttempts) {
                    attempts++
                    setTimeout(tryFindImage, 100)
                }
            }

            // Start checking after the next DOM update
            this.$nextTick(tryFindImage)
        },
        async uploadFiles(files: File[], position?: Position) {
            for (let i = 0; i < files.length; i++) {
                const original = files[i]
                const pages = document.getElementsByClassName('render-page')
                const pageIndex = this.currentDocumentInViewport(pages)
                const image = new ImageModel(files[i].name, pageIndex)
                try {
                    if (this.filetypes.includes(files[i].type)) {
                        const { width, height } = await this.loadImageProperties(files[i])
                        if (width > image.width) {
                            image.height = image.width / (width / height)
                        } else {
                            image.height = height
                        }
                    }
                    const { x, y } = this.calculatePosition(image, position)

                    image.x = x
                    image.y = y

                    await this.setLoadingImage(!this.isInlineImage)
                    await this.addNewAttachmentToDocument([image])

                    const { id, thumb } = await ImageUploadApi.upload(original)
                    image.thumb = thumb
                    image.objectId = id
                    image.ratio = image.width / image.height
                    image.uploading = false
                    image.lockAspectRatio = true

                    await this.updateFile(image)
                } catch (error: any) {
                    await this.removeFailedAttachmentFromDocument(image)
                    const errorMessage = this.getErrorMessage(error)
                    this.$bvToast.toast(errorMessage, dangerToast)
                } finally {
                    await this.setLoadingImage(false)
                    if (this.isInlineImage) await this.$store.dispatch('document/setIsInlineImage', false)
                }
            }
            await this.close()
        },
        addEventListeners() {
            const dropZone = this.$refs['stickerDropZone'] as HTMLElement

            if (dropZone) {
                dropZone.addEventListener('dragenter', (e: any) => {
                    e.preventDefault()
                })
                dropZone.addEventListener('dragleave', (e) => {
                    e.preventDefault()
                })
                dropZone.addEventListener('dragover', (e: any) => {
                    e.preventDefault()
                })
                dropZone.addEventListener('drop', async (e: any) => {
                    e.preventDefault()
                    if (this.isDroppable(e.dataTransfer?.files)) {
                        const files = Array.from(e.dataTransfer.files) as File[]
                        await this.handleDrop(e, files)
                    }
                })
            }
        },
        async loadImageProperties(file: File): Promise<any> {
            return new Promise((resolve) => {
                const fr = new FileReader()
                fr.onload = function () {
                    const img = new Image()
                    img.onload = function () {
                        resolve({
                            width: img.width,
                            height: img.height,
                        })
                    }

                    img.src = fr.result as any
                }
                fr.readAsDataURL(file)
            })
        },
        async updateFile(image: ImageModel, persist = true) {
            image.entity = this.entityType
            await this.$store.dispatch('document/updateImage', image)
            if (persist) {
                this.$store.dispatch('document/requestUpdateDocument')

                if (this.isLoggedIn) {
                    await this.$store.dispatch(this.persistAction)
                }
            }
        },
        calculatePosition(image: ImageModel, position?: Position): Position {
            let x = 300
            let y = 300

            if (position) {
                const document = this.$refs['stickerDropZone'] as HTMLElement
                const originalPageSize = {
                    width: document.getBoundingClientRect().width / this.zoomRatio,
                    height: document.getBoundingClientRect().height / this.zoomRatio,
                }
                const left = position.x - (image.offsetX || (image.width * image.ratio) / 2)
                const top = position.y - (image.offsetY || image.height * image.ratio)

                x = Math.min(Math.max(0, left), originalPageSize.width - image.width)
                y = Math.min(Math.max(0, top), originalPageSize.height - image.width)
            } else {
                const pages = document.getElementsByClassName('render-page')
                const pageIndex = this.currentDocumentInViewport(pages)
                const page = pages[pageIndex]
                const pageRect = page?.getBoundingClientRect()

                if (!pageRect) return { x, y }

                if (pageRect.bottom > this.stickyHeaderHeight + (image.height || 150) * image.ratio) {
                    const visibleY = page.clientHeight - pageRect.bottom + this.stickyHeaderHeight
                    y = Math.max(300, visibleY)
                }
            }

            return { x, y }
        },
        isDroppable(files?: FileList): boolean {
            if (!files) return !!this.draggedImage

            const fileArray = Array.from(files)

            return this.draggedImage || fileArray.length
        },
        async removeFailedAttachmentFromDocument(image: ImageModel) {
            await this.$store.dispatch('document/removeFailedImage', { image, entity: this.entityType })
        },
        getErrorMessage(error: any): string {
            if (error?.response?.data?.errors?.image?.[0]) {
                return error.response.data.errors.image[0]
            }
            return 'Sorry! An error occurred while uploading the image'
        },
    },
})
