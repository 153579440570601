<template>
    <div class="answer-min">
        <AnswerSpan ref="answerSpan" :class="{ 'no-answer': noAnswer }" :answer="answer" />
    </div>
</template>

<script>
import AnswerSpan from './AnswerSpan.vue'

export default {
    components: { AnswerSpan },
    props: {
        answer: {
            type: String,
            default: '',
        },
        isWidgetClosed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            noAnswer: false,
        }
    },
    watch: {
        answer(val) {
            this.noAnswer = val === '-'
        },
    },
    mounted() {
        this.noAnswer = this.answer === '-'
        this.$emit('height', this.$refs.answerSpan?.$el?.getBoundingClientRect().height)
    },
}
</script>

<style scoped>
.answer-min {
    min-width: 100px;
    min-height: 19px;
}

.no-answer {
    opacity: 0 !important;
}
</style>
